
import axios from 'axios';
import React, { useState, useEffect } from "react";
import "../style.css";
import Step from "./Step";

const Upload = () => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [downloadLink, setDownloadLink] = useState(null);
  const [loading, setLoading] = useState(false);
  const [parseButton, setParseButton] = useState(false);
  const [stepValue, setStepValue] = useState(0);

  useEffect(() => {
    const handleTokenExpiration = () => {
      const token = localStorage.getItem('token');
      if (isTokenExpired(token)) {
        window.location.href = '/login';
      }
    };

    const intervalId = setInterval(handleTokenExpiration, 1000 * 60);
    handleTokenExpiration();

    return () => clearInterval(intervalId);
  }, []);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setError(null);
    } else {
      setFile(null);
      setMessage("");
      setError("Please select a valid docx file.");
    }
  };

  const isTokenExpired = (token) => {
    if (!token) return true;
    const payload = JSON.parse(atob(token.split('.')[1]));
    const expirationTime = payload.exp * 1000;
    const currentTime = new Date().getTime();
    return currentTime > expirationTime;
  };

  const downloadButtonHandler = (downloadLink) => {
    const a = document.createElement("a");
    a.href = downloadLink;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    setDownloadLink(null);
    setMessage("");
    setParseButton(false);
    setStepValue(0);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!file) {
      setError("Please select a valid docx file.");
      return;
    }

    setLoading(true);
    setStepValue(1);

    try {
      const formData = new FormData();
      formData.append("file", file);
      const apiUrl = 'https://docxapi.met-r.io/api/upload';
      // const apiUrl = 'http://localhost:8000/api/upload';
      const response = await axios.post(apiUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          token: localStorage.getItem("token") || null,
        },
      });

      if (response.status === 200) {
        setError("");
        setMessage(response.data.message);
        setParseButton(true);
      } else {
        setError(response.data.error || "File upload failed.");
      }
    } catch (error) {
      setError(error.response?.data?.error || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const convertHandler = async () => {
    setLoading(true);
    setMessage("");
    setError("");
  
    try {
      const apiUrl = "https://docxapi.met-r.io/api/convertDocxToDita";
      // const apiUrl = "http://localhost:8000/api/convertDocxToDita";
      const fileHistoryUrl = "https://docxapi.met-r.io/api/fileHistory";
      // const fileHistoryUrl = "http://localhost:8000/api/fileHistory";
      const checkUpUrl = "https://docxapi.met-r.io/api/checkPreflight";
      // const checkUpUrl = "http://localhost:8000/api/checkPreflight";

      const token = localStorage.getItem("token") || null;
  
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
  
      if (token) {
        config.headers.token = token;
      }
  
      const [response1, response2, response3] = await Promise.all([
        axios.get(apiUrl, config),
        axios.post(fileHistoryUrl, {}, config),
        axios.post(checkUpUrl, {}, config),
      ]);

      if (response1.status === 200 && response2.status === 201 && response3.status === 200) {
        setMessage(response1.data.message);
        setDownloadLink(response1.data.downloadLink);
        setParseButton(false);
        setStepValue(2);
        setError("");
      } else {
        let errorMessage = "Conversion failed.";
        if (response1.status !== 200) {
          errorMessage = response1.data.message || errorMessage;
        } else if (response2.status !== 201) {
          errorMessage = response2.data.message || errorMessage;
        } else if (response3.status!==200) {
          errorMessage = response3.data.message || errorMessage;
        }
   
        setError(errorMessage);
      }

    } catch (error) {
      setError(error.response.data.message);
      setParseButton(false)
  
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="py-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <Step stepValue={stepValue} />
          </div>
          <div className="col-lg-8">
            <form onSubmit={handleSubmit} className="mt-4 mt-md-0">
              {stepValue === 0 && (
                <div
                  className="input-group mb-3 mx-auto"
                  style={{ maxWidth: "500px" }}
                >
                  <input
                    className="form-control w-25 mx-auto"
                    type="file"
                    id="formFile"
                    onChange={handleFileChange}
                    accept=".docx"
                  />
                  <button
                    className="btn btn-outline-secondary text-white border-0"
                    type="submit"
                    id="button-addon2"
                    style={{ background: "#71A5CB" }}
                  >
                    Upload
                  </button>
                </div>
              )}
              {/* {error && <div className="text-danger">{error}
              <button >Go to Home<button/>
              </div>
              } */}

     {error && (
     <div className="text-danger">
      {error}

    <button
                  className="btn btn-info d-block mx-auto mt-3 text-white border-0"
                  style={{ background: "#71A5CB" }}
                  type="button"
                  id="button-addon2"
                 onClick={() => window.location.href = '/'}>
                 Back to Step 1</button>
              </div>
)}
              {message && (
                <div className="text-success text-center">
                  <span className="my-2">{message}</span>
                </div>
              )}
              {parseButton && (
                <button
                  className="btn btn-info d-block mx-auto mt-3 text-white border-0"
                  style={{ background: "#71A5CB" }}
                  type="button"
                  id="button-addon2"
                  onClick={convertHandler}
                >
                  Convert to dita format.
                </button>
              )}
              {downloadLink && (
                <button
                  className="text-decoration-none btn mt-4 text-white"
                  style={{ background: "#FE5E45" }}
                  onClick={() => downloadButtonHandler(downloadLink)}
                >
                  Download output
                </button>
              )}
              {loading && (
                <div className="spinner-border text-primary mt-4" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Upload;
